import React , { useState, useRef } from 'react';
import { inject } from 'mobx-react';
import { Link } from 'react-router-dom';

import { Form, Input, Button, Checkbox, Typography, Row, Col, message} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import flex from "../../gx-web-ui/flex";
const { Text } = Typography;

const LoginForm = (props) => {  
  const { appStore, userRole = 'Agency', logo, id, onLogin } = props;

  const [loginError, setLoginError] = useState(false);
  const [type, setType] = useState('login');
  const [code, setCode] = useState('')
  const registerDataRef = useRef(null)
  const registerResponseRef = useRef(null)
  const [loading, setLoading] = useState(false)

  const onFinish = values => {
    if (loading) {
      return
    }

    const { username, password } = values;
    login(username, password);
  };

  const login = async (username, password) => {
    setLoading(true)

    try {
      setLoginError(false);
      await appStore.client.login(username, password, null, {id: id});      
      onLogin()
    } catch (error) {   
      let errorText = error.response ? ((error.response.body && error.response.body.error) || error.response.text) : error.message;  
      setLoginError(errorText);
    }

    setLoading(false)
  }

  const onFinishRegister = async (values) => {
    if (loading) {
      return
    }

    const data = {
      username: values.username,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      invitationInfo: {id}
    }
    console.log('on click register, data', data)
    registerDataRef.current = data

    try {
      setLoginError(false);
      const res = await appStore.client.register(data);      
      console.log('res', res)
      registerResponseRef.current = res
      setType('verify')

    } catch (error) {   
      let errorText = error.response ? ((error.response.body && error.response.body.error) || error.response.text) : error.message;  
      console.log('err', error.response)
      setLoginError(errorText);
    }
  }

  const onFinishVerify = async (values) => {
    if (loading) {
      return
    }

    const {code} = values
    console.log('on click activate, data', code)

    try {
      setLoginError(false);
      const mobile = registerDataRef.current.username

      const res = await appStore.client.activate({
        id: registerResponseRef.current.id,
        mobile: registerResponseRef.current.mobile},
        code);      
      console.log('res', res)
      message.success('Activate successfully')

      await login(registerDataRef.current.username, registerDataRef.current.password)
      //onLogin()
    } catch (error) {   
      let errorText = error.response ? ((error.response.body && error.response.body.error) || error.response.text) : error.message;  
      console.log('err', error.response)
      setLoginError(errorText);
    }
  }

  const themeColor = userRole === 'Agency' ? '#009999' : userRole === 'Client' ? '#df6054' : '#F2B341'
  const buttonStyle = userRole === 'Client' ? {backgroundColor: 'black', borderColor: 'black'} : undefined

  const buildHeader = () => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', marginBottom: 20, marginTop: 20, marginLeft: 0, justifyContent: 'center', alignItems: 'center'}}>
            <div>
                <img src={logo} width={150} height={36} />
            </div>
            <Text style={{ marginTop: 16, fontSize: 15 }} >
              { type === 'verify' ? 'Enter the verification code sent to your mobile phone' 
                : 
                `${userRole} account activation`}</Text>
        </div>
    )
  }

  const changeType = (type) => {
      setType(type)
      setLoginError(false);
  }

  const buildLoginContent = () => {
    return (
      <div style={{ ...flex.vBox(), backgroundColor: 'white', height: '100%', width: '100%' }}>
        {buildHeader()}
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your username',
              },
            ]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email or mobile number" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password',
              },
            ]}
            style={{ marginBottom: 8 }}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <div style={{ ...flex.hBox(), marginTop: 25 }}>
            {/* <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>                
            </Form.Item>
            <span style={{padding: 4}}>
              <Link className="login-form-forgot" to="/forget-password">
              Forgot password
              </Link>
            </span>   */}
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" block style={buttonStyle}>
              {loading ? 'loading...' : 'Log in'}
            </Button>      
            {<Typography.Text type={ loginError ? "danger" : null }>{loginError || ' '}</Typography.Text>}
          </Form.Item>
          
        </Form>
        <div style={{...flex.hBox('center', 'center')}}>
          <Text style={{color: themeColor, cursor: 'pointer', textDecoration: 'underline'}} onClick={() => changeType('register')}>Register a new account</Text>
        </div>
      </div>
    );
  }

  const buildRegisterContent = () => {
    return (
      <div style={{ ...flex.vBox(), backgroundColor: 'white', height: '100%', width: '100%' }}>
        {buildHeader()}
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishRegister}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your mobile number',
              },
            ]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Mobile number" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password',
              },
            ]}
            style={{ marginBottom: 8, }}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item
            name="retypePassword"
            rules={[
              {
                required: true,
                message: 'Please retype your password',
              },
            ]}
            style={{ marginBottom: 22, marginTop: 22 }}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Retype password"
            />
          </Form.Item>

          <div style={{...flex.hBox('center', 'center')}}>
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Please input your first name',
                },
              ]}
              style={{ marginBottom: 22, marginTop: 0, marginRight: 10 }}
            >
              <Input placeholder="First name" />
            </Form.Item>
            <Form.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Please input your last name',
                },
              ]}
              style={{ marginBottom: 22, marginTop: 0, marginLeft: 10 }}
            >
              <Input placeholder="Last name" />
            </Form.Item>
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" block style={buttonStyle}>
              Register
            </Button>      
            {<Typography.Text type={ loginError ? "danger" : null }>{loginError || ' '}</Typography.Text>}
          </Form.Item>
          
        </Form>
        <div style={{...flex.hBox('center', 'center')}}>
          <Text style={{color: themeColor, cursor: 'pointer', textDecoration: 'underline'}} onClick={() => changeType('login')}>Login</Text>
        </div>
      </div>
    );
  }

  const buildVerifyContent = () => {
    return (
      <div style={{ ...flex.vBox(), backgroundColor: 'white', height: '100%', width: '100%' }}>
        {buildHeader()}
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishVerify}
        >
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: 'Please input verification code',
              },
            ]}
          >
            <Input placeholder="Verification code" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" block buttonStyle={buttonStyle}>
              Active
            </Button>      
            {<Typography.Text type={ loginError ? "danger" : null }>{loginError || ' '}</Typography.Text>}
          </Form.Item>
          
        </Form>
        <div style={{...flex.hBox('center', 'center')}}>
          <Text style={{color: themeColor, cursor: 'pointer', textDecoration: 'underline'}} onClick={() => changeType('login')}>Login</Text>
        </div>
      </div>
    );
  }

  return (
    <div style={{width: '100%', height: '100%', overflowY: 'hidden', backgroundColor: 'white', flex: 1}}>
      <Row justify="center" align="middle" style={{height: '100%',  backgroundColor: 'white'}}>
          <Col span={6} style={{height: '100%'}}>
            { type === 'login' ? buildLoginContent() : type === 'register' ? buildRegisterContent() : buildVerifyContent() }
          </Col>
      </Row>
      <div style={{height: 500, display: 'flex', flexDirection: 'row', alignItems: 'stretch', backgroundColor: 'white'}} />

    </div>
  )
};

export default inject('appStore')(LoginForm);
