import React from 'react';
import LoginForm from 'commons/components/InvitedLoginForm'
import TeamLinkLogo from '../assets/icons/TEAMLINK-logo.png'
import { navigate, Location } from '@reach/router'
import withRoot from '../withRoot'

export default withRoot(function () {   
    

    return (<Location>{({ location }) => {
        if (location.href) {
            const searches = location.href.substr(location.href.lastIndexOf('?') + 1).split('&');
            const id = searches.length > 0 ? searches[0].split('=')[1] : '';

            return <LoginForm userRole={'Client'} logo={TeamLinkLogo} id={id} onLogin={() => navigate('/')} />;
        }

        return <div>Loading...</div>
        }}
        
        </Location>
    )
}
)
